import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { validateEmail } from "src/helpers/validate-email"
import Grid from "@material-ui/core/Grid"

const API_URL = `${process.env.GATSBY_API_URL}/dashboard/generate_code/`
const ERR_MSG_EMAIL_TAKEN =
  "Diese Email wird bereits von einem bestehenden Account verwendet.\n"
const ERR_MSG_NAME = "Bitte gib deinen vollständigen Namen an.\n"
const ERR_MSG_ALL_CODES_TAKEN =
  "Es wurden bereits alle zur Verfügung stehenden Aktivierungscodes ausgegeben.\n"
const ERR_MSG = "Fehler\n"

export default function NameAndEmailInput(props) {
  const { validate, short, registrationCode, buttonText, successText } = props

  const [progress, setProgress] = useState(false)
  const [success, setSuccess] = useState(null)
  const [email, setEmail] = useState("")
  const [lastName, setLastName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    setSuccess(null)
    setError("")
  }, [email])

  const handleSubmit = e => {
    if (e) {
      e.preventDefault()
    }

    if (!(email && lastName && firstName)) {
      setError("Bitte fülle alle Felder aus")
    }

    if (!validateEmail(email)) {
      setSuccess(false)
      return
    }

    let customError
    if (validate) {
      customError = validate(email)
    }

    if (customError) {
      setError(customError)
      return
    }

    if (email && lastName && firstName) {
      setProgress(true)
      fetch(API_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          registration_code: registrationCode,
          email,
          first_name: firstName,
          last_name: lastName,
        }),
      })
        .then(response => {
          if (response.ok) {
            setSuccess(true)
            return
          }
          response.json().then(data => {
            if (response.status === 400) {
              if (Object.prototype.hasOwnProperty.call(data, "email")) {
                setError(error + ERR_MSG_EMAIL_TAKEN)
              }
              if (Object.prototype.hasOwnProperty.call(data, "max_user")) {
                setError(error + ERR_MSG_ALL_CODES_TAKEN)
              }
              if (Object.prototype.hasOwnProperty.call(data, "first_name")) {
                setError(error + ERR_MSG_NAME)
              }
              if (Object.prototype.hasOwnProperty.call(data, "last_name")) {
                setError(error + ERR_MSG_NAME)
              }
              return
            }
            setError(ERR_MSG)
          })
        })
        .catch(err => {
          setError(err)
          return
        })
        .then(() => setProgress(false))
    }
  }

  const renderEmailInput = () => (
    <input
      type="email"
      id="email"
      name="email"
      placeholder={"E-Mail-Adresse"}
      disabled={progress || success === true}
      required
      value={email}
      onChange={({ target }) => setEmail(target.value)}
      className={cx({
        success: success === true,
        error,
        progress,
      })}
    />
  )

  const renderNameInput = () => (
    <input
      type="lastName"
      id="lastName"
      name="lastName"
      placeholder={"Nachname"}
      disabled={progress || success === true}
      required
      value={lastName}
      onChange={({ target }) => setLastName(target.value)}
      className={cx({
        success: success === true,
        error,
        progress,
      })}
    />
  )

  const renderFirstName = () => (
    <input
      type="firstName"
      id="firstName"
      name="firstName"
      placeholder={"Vorname"}
      disabled={progress || success === true}
      required
      value={firstName}
      onChange={({ target }) => setFirstName(target.value)}
      className={cx({
        success: success === true,
        error,
        progress,
      })}
    />
  )

  const renderError = () =>
    error && <div className="result-display error">{error}</div>

  const renderSubmitButton = () => {
    return (
      <div className="submit-button">
        {!success && (
          <button
            type="submit"
            disabled={progress || success === true}
            onClick={handleSubmit}
            className={cx({ progress })}
          >
            {buttonText}
          </button>
        )}
        {success === true && (
          <div className="result-display">{successText} 🎉</div>
        )}
      </div>
    )
  }

  return (
    <form
      className={cx("form", { "form-short": short })}
      onSubmit={handleSubmit}
    >
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            {renderFirstName()}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {renderNameInput()}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {renderEmailInput()}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          {renderSubmitButton()}
        </Grid>
      </Grid>
      {renderError()}
    </form>
  )
}

NameAndEmailInput.propTypes = {
  short: PropTypes.bool.isRequired,
  registrationCode: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  successText: PropTypes.string,
  validate: PropTypes.func,
}

NameAndEmailInput.defaultProps = {
  buttonText: "Testcode anfordern",
  successText: "Erfolg",
}
